import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row, Spinner, Toast } from 'react-bootstrap';
import NetworkContext from '../context/network-context';
import { useAccount } from '../state/account';
import { ProviderUnavailableError } from "../web3/providers/injected";
import { connectWallet, disconnectWallet, isWalletUnlocked, subscribeToNeworkChange, subscribeToNeworkConnect, subscribeToTargetNeworkChange, TARGET_NETWORK } from '../web3/web3';

function useConnected() {
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        subscribeToNeworkConnect('main', (data) => {
            setConnected(data);
        });
    }, [setConnected]);
    return connected;
}

function useNetworkChange() {
    const [network, setNetwork] = useState(null);
    useEffect(() => {
        subscribeToNeworkChange('main', setNetwork);
    }, [setNetwork]);

    return network;
};

function useTargetNetworkChange() {
    const [network, setNetwork] = useState(TARGET_NETWORK);
    useEffect(() => {

        subscribeToTargetNeworkChange('main', (network) => {
            console.log("Target network changed", network);
            setNetwork(network);
        });
    }, [setNetwork]);

    return network;
};

function useIsCorrectNetwork() {
    const [status, setStatus] = useState();
    const network = useNetworkChange();
    const TARGET_NETWORK = useTargetNetworkChange();

    useEffect(() => {
        setStatus(TARGET_NETWORK === 'any' || +network === +TARGET_NETWORK);
    }, [setStatus, network, TARGET_NETWORK])
    return status;
};

const WebThreeEnvironment = ({ children }) => {

    const [modalStatus, setModalStatus] = useState(null);
    const toggleModal = () => setModalStatus(!!!modalStatus);
    const [pendingTransaction, setPendingTransaction] = useState(false);

    const [showWalletsModal, setShowWalletsModal] = useState();
    const handleClose = () => setShowWalletsModal(false);
    const connect = () => setShowWalletsModal(true);
    const [unlocked, setUnlocked] = useState(false);

    const network = useNetworkChange();
    const isCorrectNetwork = useIsCorrectNetwork();
    const account = useAccount();
    const connected = useConnected();

    // Here's how we'll keep track of our component's mounted state
    const componentIsMounted = useRef(true);
    useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []); // Using an empty dependency array ensures this only runs on unmount

    useEffect(() => {

        if (connected && showWalletsModal) {
            setShowWalletsModal(false);
        }
    }, [connected, showWalletsModal, setShowWalletsModal]);

    useEffect(() => {
        const fn = async () => {
            const unlocked = await isWalletUnlocked();
            setUnlocked(unlocked);
        };
        fn();
    }, [connected]);

    const executeTransaction = async ({ message, tx, throwErr }) => {
        let status = false;
        let receipt;
        setModalStatus({ message, title: 'Pending Transaction' });
        setPendingTransaction(true);
        try {

            receipt = await tx();
            status = true;
            setPendingTransaction(false);

            setTimeout(() => {
                setModalStatus(null);
            }, 2000);
        } catch (err) {
            setModalStatus(null);
            let message = 'Processing Transaction Failed';
            if (err && err.data && err.data[0]) {
                const data = err.data;
                const txHash = Object.keys(data)[0]; // TODO improve
                message = data[txHash].reason;
            }

            setModalStatus({ message, title: 'Error' });
            setPendingTransaction(false);
            setTimeout(() => {
                setModalStatus(null);
            }, 2000);
            if (throwErr) {
                console.log("error")
                throw err;
            }
        }


        return { status, receipt };
    };

    const connectTo = async (providerName, isMetamask) => {
        try {
            await connectWallet(providerName);
        } catch (error) {
            console.log(error);

            if (error.name === ProviderUnavailableError.name) {
                if (isMetamask) {
                    window.open('https://metamask.io/download.html', '_blank').focus();
                    return;
                } else {
                    setModalStatus({ message: 'No provider found', title: 'Ops' });

                    setTimeout(() => {
                        if (componentIsMounted.current) {
                            setModalStatus(null);
                        }
                    }, 5000);
                }
                return;
            }
        }
    };

    const disconnect = async () => {
        try {
            await disconnectWallet();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Toast
                style={{
                    position: 'fixed',
                    top: '10%',
                    right: '1%',
                    minWidth: '300px',
                    zIndex: 999999
                }}
                delay={5000}
                show={!!modalStatus} onClose={toggleModal}>
                <Toast.Header closeButton={false}>
                    <strong className="me-auto">{modalStatus && modalStatus.title}</strong>
                    <Spinner className="ms-auto" animation="grow" size="sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </Toast.Header>
                <Toast.Body className="text-primary-gradient">
                    {modalStatus && modalStatus.message}
                </Toast.Body>
            </Toast>
            <NetworkContext.Provider value={{
                pendingTransaction: pendingTransaction,
                network: network,
                account: account,
                connected: connected,
                isCorrectNetwork: isCorrectNetwork,
                unlocked: unlocked,
                connect: connect,
                executeTransaction: executeTransaction,
                disconnect: disconnect,
                setShowWalletsModal: setShowWalletsModal
            }}>
                {children}
            </NetworkContext.Provider>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered show={showWalletsModal} onHide={handleClose} >
                <Modal.Header closeButton closeVariant='white' >
                    <Modal.Title className="mb-0">Connect Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <div className="modal-row-item" role="button" tabIndex={0} onKeyPress={() => connectTo('INJECTED', true)} onClick={() => connectTo('INJECTED', true)}>
                                <h5>Metamask</h5>
                                <StaticImage quality={100} objectFit="contain" src="../images/metamask.svg" alt="metamask" placeholder="none" width={36} />
                            </div>
                            <div className="modal-row-item" role="button" tabIndex={0} onKeyPress={() => connectTo('WALLET_CONNECT', true)} onClick={() => connectTo('WALLET_CONNECT')}>
                                <h5>Coinbase Wallet</h5>
                                <StaticImage quality={100} objectFit="contain" src="../images/coinbase.svg" alt="wc" placeholder="none" width={40} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="modal-row-item" role="button" tabIndex={0} onKeyPress={() => connectTo('INJECTED', true)} onClick={() => connectTo('INJECTED')}>
                                <h5>Trust Wallet</h5>
                                <StaticImage quality={100} objectFit="contain" src="../images/trustwallet.svg" alt="trust wallet" placeholder="none" width={40} />
                            </div>
                            <div className="modal-row-item" role="button" tabIndex={0} onKeyPress={() => connectTo('WALLET_CONNECT', true)} onClick={() => connectTo('WALLET_CONNECT')}>
                                <h5>WalletConnect</h5>
                                <StaticImage quality={100} objectFit="contain" src="../images/walletconnect.svg" alt="wc" placeholder="none" width={40} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} className="pb-0 text-center">
                            <p >
                                Web3Pay does not collect private information related to your crypto wallet. Make sure that you are on the official Web3Pay.org website. <br />By connecting a wallet, you agree to Web3Pay <Link onClick={handleClose} style={{ color: '#007bff', display: 'inline-block' }} to="/terms">Terms of Service.</Link>
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default WebThreeEnvironment;
