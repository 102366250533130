import React from "react";

const NetworkContext = React.createContext({
    pendingTransaction: false,
    network: null,
    unlocked: false,
    isCorrectNetwork: null,
    connected: false,
    account: null,
    executeTransaction: () => new Promise(),
    connect: (providerName, isMetamask) => new Promise(),
    disconnect: () => new Promise()
});

export default NetworkContext;