import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './account';

const createStore = () => configureStore({
  reducer: {
    account: accountReducer
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export default createStore;